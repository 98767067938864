import { useEffect, useReducer, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { FormGroup, Input, Row, Col, FormFeedback } from "reactstrap";

import { UserForm } from "../../../../../models/Admin/User";
import useUserService from "../../../../../services/user.service";
import useUsersService from "../../../../../services/users.service";

import Domains from "../Domains/Domains";
import ShowDomains from "../Domains/ShowDomains";
import Alert from "../Domains/Alert";

function User(props: any) {
  const { t } = useTranslation();
  const id = useRef(props.id);
  const [userDomains, setUserDomains] = useState<number[]>([]);
  const { getDomains: getUserDomains, toggleDomain } = useUserService();
  const { getUsersByEmail, getUsersByUsername } = useUsersService();
  const [showDomains, setShowDomains] = useState<boolean>(false);
  const [transactionSuccess, setTransactionSuccess] = useState<boolean>(false);
  const [transactionFinished, setTransactionFinished] =
    useState<boolean>(false);
  const [domainsDisabled, setDomainsDisabled] = useState(false);
  const contact_id = useRef<any>(null);
  const [formState, dispatchFormState] = useReducer(
    (
      state: UserForm,
      action: {
        type: "EMAIL" | "USERNAME" | "PASSWORD" | "ACTIVE" | "DOMAINS";
        value: any;
      }
    ): UserForm => {
      let { username, email, password, active, domains } = state;
      const fieldToModify = action.type;
      switch (fieldToModify) {
        case "USERNAME":
          username = action.value;
          break;
        case "EMAIL":
          email = action.value;
          break;
        case "PASSWORD":
          password = action.value;
          break;
        case "ACTIVE":
          active = action.value;
          break;
        case "DOMAINS":
          if (action.value.checked === true) {
            domains = [...domains, action.value.domain];
          } else {
            domains = domains.filter(
              (currentDomain: number) => currentDomain !== action.value.domain
            );
          }
          break;
        default:
          throw new Error("invalid input");
      }
      const newState = new UserForm(username, email, password, active, domains);
      props.onChange({
        id: id.current,
        username,
        email,
        password,
        active,
        type: props.type,
        isValid: newState.validateAll(),
        domains,
      });
      return newState;
    },
    new UserForm(
      props.username,
      props.email,
      props.password,
      true,
      props.domains
    )
  );
  const { username, password, email } = formState;

  const [passwordIsTouched, setPasswordIsTouched] = useState(false);
  const passwordInputIsValid = formState.passwordIsValid || !passwordIsTouched;
  function handleChangePassword(e: any) {
    const currentValue = e.target.value;
    setPasswordIsTouched(true);
    dispatchFormState({ type: "PASSWORD", value: currentValue });
  }

  const [usernameIsTouched, setUsernameIsTouched] = useState(false);
  const usernameInputIsValid = formState.usernameIsValid || !usernameIsTouched;
  function handleChangeUsername(e: any) {
    const currentValue = e.target.value;
    setUsernameIsTouched(true);
    dispatchFormState({ type: "USERNAME", value: currentValue });
  }

  const [emailIsTouched, setEmailIsTouched] = useState(false);
  const emailInputIsValid = formState.emailIsValid || !emailIsTouched;
  function handleChangeEmail(e: any) {
    const currentValue = e.target.value;
    setEmailIsTouched(true);
    dispatchFormState({ type: "EMAIL", value: currentValue });
  }
  function updateDomains() {
    if (id.current < 0) return setUserDomains(props.domains);
    getUserDomains({
      fn: (data: any) => {
        setUserDomains(data.data);
      },
      id: id.current,
    });
  }
  useEffect(() => {
    updateDomains();
  }, []);

  function handleCheck(domainId: number, checked: boolean) {
    setDomainsDisabled(true);
    setTimeout(() => {
      setDomainsDisabled(false);
    }, 3000);

    if (id.current < 0) {
      dispatchFormState({
        type: "DOMAINS",
        value: { domain: domainId, checked },
      });
      return;
    }

    toggleDomain({
      fn: (data: any) => {
        try {
          if (data.status === 200) {
            const currentUserDomain = data.data.map(
              (item: any) => item.user_id
            );
            setUserDomains((previousValue: number[]) => {
              return previousValue.filter((userDomain: number) =>
                currentUserDomain.includes(userDomain)
              );
            });
            setTransactionSuccess(true);
            return data;
          }
        } catch (error) {
          setTransactionSuccess(false);
        }
      },
      userId: id.current,
      domainId,
    });

    setTransactionFinished(true);

    updateDomains();
  }
  function handleShowDomains() {
    setShowDomains((previousValue: boolean) => !previousValue);
  }
  const transactionResult = {
    type: transactionSuccess ? "success" : "danger",
    title: transactionSuccess ? t("success") + "!" : t("something_goes_wrong"),
    description: transactionSuccess
      ? t("domain_updated")
      : t("error_domain_updated"),
  };
  function handleCloseClick() {
    setTransactionFinished(false);
  }
  const [isEmailTaken, setIsEmailTaken] = useState(props.emailTaken);
  const [isUsernameTaken, setIsUsernameTaken] = useState(props.usernameTaken);

  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      if (
        (formState.emailIsValid &&
          (formState.email !== props.email || props.id < 0)) ||
        props.emailTaken
      ) {
        const existingEmailsResponse: any = await getUsersByEmail(
          formState.email
        );

        const existingEmails = existingEmailsResponse.data;
        if (existingEmails.length > 0 || props.emailTaken) {
          contact_id.current = existingEmails[0]?.contact_id;
          id.current = existingEmails[0]?.id ?? id.current;
          setIsEmailTaken(true);
        } else {
          setIsEmailTaken(false);
        }
      } else {
        if (isEmailTaken === true) setIsEmailTaken(false);
      }
      if (
        formState.usernameIsValid &&
        (formState.username !== props.username || props.id < 0)
      ) {
        const existingUsernamesResponse: any = await getUsersByUsername(
          formState.username
        );

        const existingUsernames = existingUsernamesResponse.data;
        if (existingUsernames.length > 0 || props.usernameTaken) {
          contact_id.current = existingUsernames[0]?.contact_id;
          id.current = existingUsernames[0]?.id ?? id.current;
          setIsUsernameTaken(true);
        } else {
          setIsUsernameTaken(false);
        }
      } else {
        if (isUsernameTaken === true) setIsUsernameTaken(false);
      }
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [formState]);

  props.onUserTaken({
    id: id.current,
    isTaken: isUsernameTaken,
    contact_id: contact_id.current,
  });

  props.onEmailTaken({
    id: id.current,
    isTaken: isEmailTaken,
    contact_id: contact_id.current,
  });
  const invalidEmailInput = !emailInputIsValid || isEmailTaken;
  const invalidUsernameInput = !usernameInputIsValid || isUsernameTaken;

  const emailFormFeedbackMessage = isEmailTaken
    ? "email already exists"
    : "invalid email";

  const usernameFormFeedbackMessage = isUsernameTaken
    ? "username already exists"
    : "invalid username";

  return (
    <div style={{ marginBottom: "1.5rem" }} className="user-wrapper">
      <Row key={`user-${id}`}>
        <Col lg="3">
          <FormGroup>
            <label className="form-control-label" htmlFor="alt_address_state">
              {t("username")}
            </label>
            <Input
              className="form-control-alternative"
              type="text"
              value={username}
              invalid={invalidUsernameInput}
              onChange={handleChangeUsername}
            />
            <FormFeedback>{usernameFormFeedbackMessage}</FormFeedback>
          </FormGroup>
        </Col>
        <Col lg="3">
          <FormGroup>
            <label className="form-control-label" htmlFor="alt_address_state">
              {t("email")}
            </label>
            <Input
              className="form-control-alternative"
              type="text"
              value={email}
              invalid={invalidEmailInput}
              onChange={handleChangeEmail}
            />
            <FormFeedback>{emailFormFeedbackMessage}</FormFeedback>
          </FormGroup>
        </Col>
        <Col lg="3">
          <FormGroup>
            <label className="form-control-label" htmlFor="alt_address_state">
              {t("password")}
            </label>
            <Input
              className="form-control form-control-alternative"
              type="password"
              value={password}
              invalid={passwordInputIsValid ? false : true}
              onChange={handleChangePassword}
            />
            {!props.isValid && <FormFeedback>Invalid input</FormFeedback>}
          </FormGroup>
        </Col>
        <Col lg="3">
          <FormGroup>
            <br></br>
            <Button
              variant="danger"
              onClick={() => {
                dispatchFormState({ type: "ACTIVE", value: false });
              }}
            >
              {t("remove")}
            </Button>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <div>
          {transactionFinished && (
            <Alert {...transactionResult} onClick={handleCloseClick}></Alert>
          )}
        </div>
        <div>
          <ShowDomains onShowDomains={handleShowDomains} />
          {showDomains && (
            <Domains
              userDomains={
                props.domains?.length > 0 ? props.domains : userDomains
              }
              onCheck={handleCheck}
              disabled={domainsDisabled}
            ></Domains>
          )}
        </div>
      </Row>
    </div>
  );
}
export default User;
