import { useState, useRef, FormEvent, FC, useMemo, Fragment } from "react";
import { Modal, Button, Nav, Row, Form, Alert, Spinner } from "react-bootstrap";
import { withTranslation, useTranslation } from "react-i18next";

import { Role } from "../../../interfaces";
import RoleListItem from "./RoleListItem";

interface RoleListProps {
  roles: Role.IRole[];
  selected: Role.IRole | null;
  provided: any;
  onRoleClick: (role: Role.IRole) => void;
  onRoleDelete: (role: Role.IRole) => void;
  onRoleCreate: (event: FormEvent<HTMLFormElement>) => void;
}

const RoleList: FC<RoleListProps> = ({
  roles,
  selected,
  provided,
  onRoleClick,
  onRoleCreate,
  onRoleDelete,
}) => {
  const [t] = useTranslation();
  const [show, setShow] = useState(false);
  const [isFormEmpty, setIsFormEmpty] = useState(false);
  const nameRef = useRef<HTMLInputElement>(null);
  const descRef = useRef<HTMLTextAreaElement>(null);

  const handleOnSubmitBtn = (e: any) => {
    e.preventDefault();
    if (
      !Boolean(nameRef?.current?.value) ||
      !Boolean(descRef?.current?.value)
    ) {
      return setIsFormEmpty(true);
    }
    setShow(false);
    e.target.form.requestSubmit();
  };

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const items = useMemo(() => {
    return roles.map((role: Role.IRole, index: number) => (
      <RoleListItem
        key={"role.".concat(role.id.toString() || "0")}
        role={role}
        provided={provided}
        onRoleClick={onRoleClick}
        onRoleDelete={onRoleDelete}
        active={selected ? selected.id === role.id : index === 0}
      />
    ));
  }, [roles, selected, onRoleClick, onRoleDelete, provided]);

  if (roles.length === 0) {
    return (
      <Row className="d-flex justify-content-center">
        <Spinner animation="border" role="status" className="mx-auto" />
      </Row>
    );
  }

  return (
    <Fragment>
      <Row>
        <Button className="w-100" onClick={handleShow}>
          {t("label.action.create", { ns: "application.misc" })}
        </Button>
      </Row>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("label.action.create", { ns: "application.misc" })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onRoleCreate} id="role-form">
            <Form.Group controlId="role.name">
              <Form.Label>
                {t("roles.role_name", { context: "label" })}
                <span style={{ color: "red" }}>&nbsp;*</span>
              </Form.Label>
              <Form.Control
                placeholder={t("roles.role_name", { context: "placeholder" })}
                name="role.name"
                type="text"
                ref={nameRef}
              />
            </Form.Group>
            <Form.Group controlId="role.description">
              <Form.Label>
                {t("roles.role_description", { context: "label" })}
                <span style={{ color: "red" }}>&nbsp;*</span>
              </Form.Label>
              <Form.Control
                placeholder={t("roles.role_description", {
                  context: "placeholder",
                })}
                name="role.description"
                as="textarea"
                ref={descRef}
                rows={5}
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              className="w-100 my-2"
              onClick={handleOnSubmitBtn}
            >
              {t("label.action.create", { ns: "application.misc" })}
            </Button>
            {isFormEmpty && (
              <Alert variant="danger">
                {t("roles.role_info", { context: "error" })}
              </Alert>
            )}
          </Form>
        </Modal.Body>
      </Modal>
      <hr />
      <Row>
        <Nav
          as="div"
          variant="pills"
          ref={provided.innerRef}
          className="d-flex flex-column"
        >
          {items}
          {provided.placeholder}
        </Nav>
      </Row>
    </Fragment>
  );
};

export default withTranslation()(RoleList);
