import React, { useCallback } from "react";
import { withTranslation, TFunction } from "react-i18next";
import { Routes, Route, Link, useLocation, Navigate } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Users from "./Users";
import Roles from "./Roles";
import Domains from "./Domains";
import Profile from "./Profile";
import States from "./States";
import Menu from "./Menu";
import MenuCategory from "./Menu_category/Menu_category";
import { getPermissions } from "src/reducers/permissions";
import { useAppSelector } from "src/app/hooks";

interface ConfigPageProps {
  t: TFunction;
}

const ConfigPage = ({ t }: ConfigPageProps) => {
  const location = useLocation();
  const defaultActiveKey = React.useMemo(() => "config.root", []);
  const currentPermissions = useAppSelector(getPermissions);

  const activeKey = React.useMemo(() => {
    const [, config, path] = location.pathname.split("/");
    return config.concat(".", path || "root");
  }, [location.pathname]);

  const getPermission = useCallback(
    (entity) => {
      if (currentPermissions.permissions.length) {
        const value = currentPermissions.permissions.find(
          (p) => p.name === entity
        );
        return value?.permission || false;
      }
      return false;
    },
    [currentPermissions.permissions]
  );

  return (
    <Container fluid className="p-4">
      <Tab.Container {...{ defaultActiveKey }} activeKey={activeKey}>
        <Row>
          <Col sm={2}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/config"
                  eventKey="config.root"
                  data-window-location="/config"
                >
                  {t("config.general")}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/config/me"
                  eventKey="config.me"
                  data-window-location="/config/me"
                >
                  {t("config.user_profile")}
                </Nav.Link>
              </Nav.Item>
              {getPermission("user") && (
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/config/users"
                    eventKey="config.users"
                    data-window-location="/config/users"
                  >
                    {t("config.users")}
                  </Nav.Link>
                </Nav.Item>
              )}

              {getPermission("domain") && (
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/config/domains"
                    eventKey="config.domains"
                    data-window-location="/config/domains"
                  >
                    {t("config.domains")}
                  </Nav.Link>
                </Nav.Item>
              )}

              {getPermission("role") && (
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/config/roles"
                    eventKey="config.roles"
                    data-window-location="/config/roles"
                  >
                    {t("config.roles")}
                  </Nav.Link>
                </Nav.Item>
              )}

              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/config/states"
                  eventKey="config.states"
                  data-window-location="/config/states"
                >
                  {t("config.states")}
                </Nav.Link>
              </Nav.Item>
              {getPermission("menu") && (
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/config/menu"
                    eventKey="config.menu"
                    data-window-location="/config/menu"
                  >
                    {t("config.menu")}
                  </Nav.Link>
                </Nav.Item>
              )}
              {getPermission("category_menu") && (
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/config/menu_category"
                    eventKey="config.menu_category"
                    data-window-location="/config/menu_category"
                  >
                    {t("config.menu_category")}
                  </Nav.Link>
                </Nav.Item>
              )}
            </Nav>
          </Col>
          <Col sm={10}>
            <Routes>
              <Route path="/" element={<div>{t("config.general")}</div>} />

              <Route path="/me" element={<Profile />} />

              <Route path="/users/*" element={<Users />} />

              <Route path="/domains" element={<Domains />} />

              <Route path="/roles" element={<Roles />} />

              <Route path="/states" element={<States />} />

              <Route path="/menu/*" element={<Menu />} />

              <Route path="/menu_category/*" element={<MenuCategory />} />

              <Route path="*" element={<Navigate to="/config" replace />} />
            </Routes>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  );
};

export default withTranslation()(ConfigPage);
