import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { TFunction } from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import useMenuService from "src/services/menu.service";
import {
  Row,
  Form,
  Col,
  FloatingLabel,
  Accordion,
  Button,
} from "react-bootstrap";
import styled from "styled-components";
import useMenuCategoryService from "src/services/menu_category.service";
import { IServerResponse } from "src/interfaces";
import useEntityService from "src/services/entity.service";
import Select from "react-select";
import { useMenuContext } from "./Menu";

export const ListItem = styled.li(() => ({
  listStyleType: "number",
  "&:hover": {
    backgroundColor: "inherit !important",
    color: "inherit !important",
    cursor: "pointer",
  },
}));

interface IFormMenuProps {
  t: TFunction;
  type: "create" | "edit";
}

interface IMenu {
  category_menu: { value: string | number; label: string | number }[];
  menu_name: string;
  entity: string;
  visible: boolean;
}

interface ICategoryMenu {
  id: number;
  name: string;
  visible: boolean;
  active: boolean;
}

interface IEntity {
  name: string;
  label: string;
}

const FormMenuConext = createContext<{
  reloadUsers: () => void;
  menu: any;
}>({
  reloadUsers: () => void 0,
  menu: {},
});

const FormMenu = ({ t, type }: IFormMenuProps): JSX.Element => {
  const { listOfUsedCategories } = useMenuContext();
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    getMenuById: getSelectedMenu,
    create: createMenuById,
    edit: editMenuById,
  } = useMenuService();
  const { getMany } = useMenuCategoryService();
  const { getManyFormated } = useEntityService();
  const [categoriesSelected, setCategoriesSelected] =
    useState<Array<number>>(listOfUsedCategories);

  const [categories, setCategories] = useState<Array<any>>([]);
  const [entities, setEntities] = useState<Array<IEntity>>([]);

  const [menu, setMenu] = useReducer(
    (state: IMenu, newState: IMenu): IMenu => ({ ...state, ...newState }),
    {
      category_menu: [],
      menu_name: "",
      entity: "",
      visible: true,
    }
  );

  const reloadUsers = useCallback(() => {
    getSelectedMenu({ id })
      .then((response) => setMenu(response.data))
      .catch((error) => console.error(error));
  }, [getSelectedMenu, id]);

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data: IMenu = {
        ...menu,
      };
      switch (type) {
        case "create":
          createMenuById({
            // TODO: Include phone and phone types at creating new person.
            data,
          })
            .then((r) => navigate(`/config/menu`))
            .catch((error) => console.error(error));
          break;
        case "edit":
          editMenuById({ id, data: { data } })
            .then((r) => navigate(`/config/menu`))
            .catch((error) => console.error(error));
          break;
        default:
          console.error("Invalid form type suitable for submit.");
          break;
      }
    },
    [createMenuById, editMenuById, id, navigate, menu, type]
  );

  useEffect(() => {
    if (type === "edit" && id) {
      getSelectedMenu({ id })
        .then((response) => {
          const resMenu = response.data;
          const data: IMenu = {
            menu_name: resMenu[0].menu_name,
            entity: resMenu[0].entity,
            visible: resMenu[0].visible,
            category_menu: resMenu.map((row: any) => ({
              value: Number(row.category_id),
              label: row.category_menu_name,
            })),
          };
          setMenu({ ...data });
          if (!listOfUsedCategories?.length)
            setCategoriesSelected(
              data.category_menu.map((r) => Number(r.value))
            );
        })
        .catch((error) => console.error(error));
    }
  }, [getSelectedMenu, id, type, listOfUsedCategories?.length]);

  useEffect(() => {
    getMany({
      fn: (
        response: IServerResponse<{
          data: Array<ICategoryMenu>;
        }>
      ) => {
        setCategories(response.data);
      },
    });
  }, [getMany]);

  useEffect(() => {
    getManyFormated(categoriesSelected, menu.entity, {
      fn: (
        response: IServerResponse<{
          data: Array<IEntity>;
        }>
      ) => {
        const res = response.data as any[] as IEntity[];
        setEntities(res);
      },
    });
  }, [getManyFormated, menu.entity, categoriesSelected, type]);

  return (
    <FormMenuConext.Provider value={{ reloadUsers, menu }}>
      <Form onSubmit={onSubmit}>
        <Row>
          <Accordion as="ol" flush defaultActiveKey="basic_information">
            <Accordion.Item eventKey="basic_information">
              <Accordion.Header>
                <ListItem className="mx-3">
                  {t("menu.basic_information")}
                </ListItem>
              </Accordion.Header>
              <Accordion.Body data-section="basic_information" className="p-2">
                <Row>
                  <Col>
                    <Form.Group as={Col} controlId="formEntity">
                      <Form.Label>{t("menu.category_id")}</Form.Label>
                      <Select
                        options={categories.map((item: any, index: any) => ({
                          value: item.id,
                          label: item.name,
                        }))}
                        value={menu.category_menu}
                        isMulti
                        onChange={(e: any) => {
                          setMenu({
                            ...menu,
                            category_menu: e,
                          });
                          setCategoriesSelected(
                            e.map((r: any) => Number(r.value))
                          );
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <FloatingLabel
                      label={t("menu.name_menu")}
                      className="my-2"
                      controlId="menu.menu_name"
                    >
                      <Form.Control
                        required
                        onChange={(e) =>
                          setMenu({ ...menu, menu_name: e.target.value })
                        }
                        value={menu.menu_name}
                        name="menu.menu_name"
                        maxLength={50}
                        type="text"
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FloatingLabel
                      label={t("menu.entity")}
                      className="my-2"
                      controlId="menu.entity"
                    >
                      <Form.Select
                        onChange={(e) =>
                          setMenu({
                            ...menu,
                            entity: e.target.value,
                          })
                        }
                        value={menu.entity}
                      >
                        <option hidden>Select one option</option>
                        {entities.map((item: any, index: any) => (
                          <option value={item.name} key={index}>
                            {item.label}
                          </option>
                        ))}
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  {type === "edit" && (
                    <Col>
                      <Form.Check
                        label={t("menu.visible")}
                        onChange={(e) =>
                          setMenu({ ...menu, visible: e.target.checked })
                        }
                        checked={menu.visible}
                        name="menu.visible"
                        type="checkbox"
                      />
                    </Col>
                  )}
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Row>
        <Row>
          <Col className="d-flex flex-row-reverse">
            <Button variant="primary" disabled={false} type="submit">
              {t("label.action.save", { ns: "application.misc" })}
            </Button>
          </Col>
        </Row>
      </Form>
    </FormMenuConext.Provider>
  );
};

export function useFormMenu() {
  const context = useContext(FormMenuConext);
  if (!context) {
    throw new Error("useFormMenu must be used within a FormMenuProvider");
  }
  return context;
}

export default withTranslation()(FormMenu);
